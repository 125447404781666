import React, { useRef, useState, useEffect } from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import '../../assets/css/special-css/messaging-bridge.css'

import MessagingBridgeImg from '../../assets/images/messaging-bridge/messagingBridge.webp'
import MessagingBridgeIcon from '../../assets/images/messaging-bridge/messagingBridgeIcon.svg'
import SafeguardYourIdentityImg from '../../assets/images/messaging-bridge/safeguardYourIdentity.webp'
import SafeguardYourIdentityIcon from '../../assets/images/messaging-bridge/safeguardYourIdentityIcon.svg'
import InnovativeReverseListImg from '../../assets/images/messaging-bridge/innovativeReverseList.webp'
import InnovativeReverseListIcon from '../../assets/images/messaging-bridge/innovativeReverseListIcon.svg'
import ExpandYourPrivacyShieldImg from '../../assets/images/messaging-bridge/expandYourPrivacyShield.webp'
import ExpandYourPrivacyShieldIcon from '../../assets/images/messaging-bridge/expandYourPrivacyShieldIcon.svg'
import SignUpNowImg from '../../assets/images/messaging-bridge/signUpNow.webp'
import SignUpNowIcon from '../../assets/images/messaging-bridge/signUpNowIcon.svg'
import MessagingBridgeVideo from '../../assets/images/messaging-bridge/messagingBridge.mp4'
import PlayImg from "../../assets/images/play-image.svg"
import VideoPlaceholderImg from "../../assets/images/messaging-bridge/videoImage.webp"


const MessagingBridge = ({ data }) => {
    const videoRef = useRef()
    const [stop, setStop] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [pulsing, setPulsing] = useState(false);

    const handleVideo = () => {
        setIsChecked(!isChecked)
        setStop(!stop)
        if (stop === true) {
        videoRef.current.pause()
        } else {
        videoRef.current.play()
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
          setPulsing((prevPulsing) => !prevPulsing);
        }, 1000); // Change the duration to adjust the pulse speed
    
        return () => {
          clearInterval(interval);
        };
      }, []);

    return (
        <Layout env={data.site.siteMetadata.env}>
        <SEO
            postTitle="Messaging Bridge | BlueMail App"
            postDescription="Take control of your privacy and communicate with confidence with the BlueMail Messaging Bridge"
            postImage="/img/OG/messaging-bridge.png"
            postURL="features-functions/messaging-bridge"
            postSEO
        />
        <Navbar />
        <div className="features-functions messaging-bridge">
            <div className="container pt-120 pb-100">
                <div className="row b">
                    <div className="col-lg-6 col-md-12 col-xs-12 m-auto content">
                        <img className="icon" src={MessagingBridgeIcon} alt='Messaging Bridge I' />
                        <h1>BlueMail <span>Messaging Bridge:</span> Secure & Private Communication</h1>
                        <p>Step into a world of secure and private communication with BlueMail's Messaging Bridge. Our revolutionary technology ensures that your personal information remains confidential while providing a seamless email experience. Say goodbye to spam, unwanted communication, and the risk of exposing your real email address. With BlueMail Messaging Bridge, you can take control of your privacy and communicate with confidence.</p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-xs-12 asset">
                        <img src={MessagingBridgeImg} alt='Messaging Bridge' />
                    </div>
                </div>            
            </div>
            <div className="container pb-100">
                <div className="row rev">
                    <div className="col-lg-7 col-md-7 col-xs-12 m-auto controls">
                        <video
                            ref={videoRef}
                            poster={VideoPlaceholderImg}
                            playsInline
                            controls={isChecked ? 'controls' : ''}
                            className="poster-messaging-bridge"
                        >
                            <source src={MessagingBridgeVideo} type="video/mp4" alt="write-gem" />
                        </video>
                        {!isChecked && (
                            <img
                            onClick={handleVideo}
                            className={pulsing ? 'video-play-image-messaging-bridge pulse' : 'video-play-image-messaging-bridge'}
                            src={PlayImg}
                            alt="play button"
                            />
                        )}
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-12 m-auto sl">
                        <img className="icon" src={SafeguardYourIdentityIcon} alt='Safeguard Your Identity I' />
                        <h2 className="right-video">Safeguard Your <span>Identity</span></h2>
                        <p>BlueMail's Messaging Bridge generates a unique incognito email address, keeping your real email address hidden from prying eyes. When someone responds to you, the message is seamlessly forwarded to your actual email address via the Messaging Bridge, ensuring that your identity remains concealed throughout the conversation. Enjoy peace of mind knowing that your private conversations stay private.</p>
                    </div>                
                </div>            
            </div>
            <div className="container pb-100">
                <div className="row">                
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img className="icon" src={InnovativeReverseListIcon} alt='Innovative Reverse List I' />
                        <h2>Innovative <span>Reverse List</span></h2>
                        <p>What sets BlueMail's Messaging Bridge apart is our innovative reverse list mechanism. Each contact is assigned a unique incognito email address, enabling consistent and recognizable communication while maintaining complete anonymity. Our technology has been patented in 2012. Experience the convenience of communicating securely without compromising on recognition and reliability.</p>
                    </div> 
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img className="mt-m" src={InnovativeReverseListImg} alt='Innovative Reverse List' />
                    </div>               
                </div>            
            </div>
            <div className="container pb-100">
                <div className="row rev b ptb">
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img src={ExpandYourPrivacyShieldImg} alt='Expand Your Privacy Shield I' />
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img className="icon" src={ExpandYourPrivacyShieldIcon} alt='Expand Your Privacy Shield I' />
                        <h2>Expand Your <span>Privacy Shield</span></h2>
                        <p>BlueMail's Messaging Bridge is especially useful when interacting with third-party services. By using an incognito email addresses, you can avoid potential spam, unwanted advertisements, and the risk of your information being shared with untrusted sources. Our commitment to user privacy and innovation has positioned BlueMail as a leader in the industry, empowering individuals and businesses alike to communicate confidently and securely.</p>
                    </div>                
                </div>            
            </div>
            <div className="container pb-100">
                <div className="row">                
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img className="icon" src={SignUpNowIcon} alt='SignUp Now I' />
                        <h2><span>Sign up Now</span></h2>
                        <p>Sign up now and discover the power of BlueMail Messaging Bridge for yourself. Take control of your privacy and enjoy seamless, secure communication like never before.</p>
                    </div> 
                    <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                        <img className="mt-m" src={SignUpNowImg} alt='SignUp Now' />
                    </div>               
                </div>            
            </div>
        </div>
        <Footer />
        </Layout>
    )
}

export default MessagingBridge

export const query = graphql`
  query MessagingBridgePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
